<template>
  <div id="profile-wrapper">
    <div id="profile-main">
      <lms-card padding>
        <h1>
          {{ $t('labels.personalInfo') }}
        </h1>

        <div class="pic-card">
          <div class="lms-image">
            <img :src="avatarImageURL(user)" />
          </div>
          <div class="lms-content">
            <div class="lms-name">
              {{ user.name }}
            </div>
            <div class="lms-email">
              {{ user.email }}
            </div>
            <div class="lms-chip">
              <v-chip>{{ $t(`labels.${user.status}`) }}</v-chip>
            </div>
          </div>
        </div>

        <div class="item">
          <table>
            <tr>
              <td>{{ $t('labels.dialCode') }}</td>
              <td>{{ user.dialCode }}</td>
            </tr>
            <tr>
              <td>{{ $t('labels.phone') }}</td>
              <td>{{ user.phone }}</td>
            </tr>
            <tr>
              <td>{{ $t('labels.city') }}</td>
              <td>{{ user.city }}</td>
            </tr>
            <tr>
              <td>{{ $t('labels.state') }}</td>
              <td>{{ user.state }}</td>
            </tr>
            <tr>
              <td>{{ $t('labels.country') }}</td>
              <td>{{ country }}</td>
            </tr>
            <tr>
              <td>{{ $t('labels.distributor') }}</td>
              <td>{{ user.distributor }}</td>
            </tr>
          </table>
        </div>

        <button @click="loginAs">
          {{ $t('labels.loginAsUser') }}
        </button>
      </lms-card>
    </div>

    <div id="profile-right">
      <lms-card bottomMargin padding>
        <h1>{{ $t('labels.LMSInfo') }}</h1>

        <div class="item">
          <table>
            <tr>
              <td>Fluke Academy ID</td>
              <td>{{ user.flukeAcademyID }}</td>
            </tr>
            <tr>
              <td>Master</td>
              <td>{{ master }}</td>
            </tr>
            <tr>
              <td>TSM</td>
              <td>{{ TSM }}</td>
            </tr>
            <tr v-if="isTSM && (hasTsmRegions || hasTsmCountries)">
              <td>{{ $t('labels.region') }} TSM</td>
              <td>
                <div v-if="hasTsmRegions">
                  <strong>Brasil:</strong> {{ formatTsmRegions }}
                </div>
                <div v-if="hasTsmCountries">
                  <strong>LAAM:</strong> {{ formatTsmCountries }}
                </div>
              </td>
            </tr>
            <tr>
              <td>{{ $t('labels.enrollmentDate') }}</td>
              <td>{{ formatFullDate(user.createdAt) }}</td>
            </tr>
            <tr>
              <td>{{ $t('labels.championSince') }}</td>
              <td>{{ formatDate(user.championSince) }}</td>
            </tr>
          </table>
        </div>
      </lms-card>

      <lms-card bottomMargin padding>
        <h1>{{ $t('labels.userClassification') }}</h1>
        <div class="lms-blue-chips">
          <v-chip v-for="(item, index) in user.classification" :key="index">
            {{ item }}
          </v-chip>
        </div>
      </lms-card>

      <lms-card padding>
        <h1>{{ $t('labels.trainingCategory') }}</h1>
        <div class="lms-blue-chips" v-if="user.trainingCategory">
          <v-chip v-for="(item, index) in user.trainingCategory" :key="index">
            {{ item }}
          </v-chip>
        </div>
      </lms-card>
    </div>
  </div>
</template>

<script>
import { avatarImageURL } from '../../services/file';
import moment from 'moment';

export default {
  data: () => ({
    TSMResult: null,
  }),
  computed: {
    user() {
      return this.$store.getters['user/current'];
    },
    country() {
      let country = '';
      try {
        const countryObj = this.$store.getters['country/current'];
        country = countryObj.name[this.$i18n.locale];
        if (!country) throw new Error('not found');
      } catch (e) {}

      return country;
    },
    master() {
      let master = '';
      try {
        master = this.user.masters[0].title;
      } catch (e) {}

      return master;
    },
    TSM() {
      let TSM = '';
      try {
        TSM = this.TSMResult[0].name;
      } catch (e) {}

      return TSM;
    },
    isTSM() {
      return this.user && this.user.type === 'tsm';
    },

    hasTsmRegions() {
      return (
        this.user &&
        this.user.TSM &&
        this.user.TSM.regions &&
        this.user.TSM.regions.length > 0
      );
    },

    hasTsmCountries() {
      // Verificar se há países além do Brasil (609c084f310e65f7ca19280d)
      return (
        this.user &&
        this.user.TSM &&
        this.user.TSM.countries &&
        this.user.TSM.countries.length > 0 &&
        this.user.TSM.countries.some((c) => c !== '609c084f310e65f7ca19280d')
      );
    },

    formatTsmRegions() {
      if (!this.hasTsmRegions) return '';
      return this.user.TSM.regions.join(', ');
    },

    formatTsmCountries() {
      if (!this.hasTsmCountries) return '';

      const countries = this.$store.state.country.list;

      // Verificar se countries é um array ou tem estrutura especial
      const countryItems = countries.items || countries;

      if (!Array.isArray(countryItems)) {
        return this.user.TSM.countries
          .filter((c) => c !== '609c084f310e65f7ca19280d')
          .join(', ');
      }

      return this.user.TSM.countries
        .filter((c) => c !== '609c084f310e65f7ca19280d')
        .map((countryId) => {
          const country = countryItems.find((c) => c._id === countryId);
          return country
            ? country.name[this.$i18n.locale] || country.name.en
            : countryId;
        })
        .join(', ');
    },
  },
  methods: {
    avatarImageURL,
    async loadData() {
      const userID = this.$route.params.id;
      await this.$store.dispatch('user/get', userID);

      // country
      if (this.user.country) {
        this.$store.dispatch('country/get', this.user.country);
      }

      await this.$store.dispatch('country/loadList');

      // TSM
      this.TSMResult = await this.$store.dispatch('user/getTSM', userID);
    },
    formatFullDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    formatDate(date) {
      try {
        return date.split('-').reverse().join('/');
      } catch (e) {
        return '';
      }
    },
    async loginAs() {
      await this.$store.dispatch('auth/loginAs', this.$route.params.id);
      this.$router.push({
        name: 'course-list',
      });
    },
  },
  beforeDestroy() {
    this.$store.commit('user/clearCurrent');
    this.$store.commit('country/clearCurrent');
  },
  created() {
    this.loadData();
  },
};
</script>
